import React, { Suspense, lazy, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./App.css";

// Lazy load the components
// const RouteOfPages = lazy(() => import("./route"));
const Home = lazy(() => import("./Home"));
const About = lazy(() => import("./About"));
const Team = lazy(() => import("./Team"));
const Research = lazy(() => import("./Research"));
const Contact = lazy(() => import("./Contact"));
const Trip = lazy(() => import("./Trip"));
const Tech = lazy(() => import("./Tech"));
const ThinkTank = lazy(() => import("./Think-Tank"));
const PrivacyPolicy = lazy(() => import("./PrivacyPolicy"));
const TermsAndConditions = lazy(() => import("./TermsAndConditions"));

// Scroll to top only on actual page navigation
const ScrollToTop = () => {
  const location = useLocation();
  const prevLocation = React.useRef(location.pathname);

  useEffect(() => {
    // Scroll to top only if the previous path is different
    if (prevLocation.current !== location.pathname) {
      window.scrollTo(0, 0);
    }
    prevLocation.current = location.pathname; // Update previous location
  }, [location]);

  return null;
};

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Suspense
        fallback={
          <h1
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Loading...
          </h1>
        }
      >
        <Routes>
          {/* <Route path="/" element={<RouteOfPages />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/research" element={<Research />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/trip" element={<Trip />} />
          <Route path="/tech" element={<Tech />} />
          <Route path="/think-tank" element={<ThinkTank />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
